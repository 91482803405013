import { ColorType } from "../../types/ColorType";
import LogoFullCircleSemicircles from "../svg/logos/LogoFullCircleSemicircles";

export type Speaker = {
  name: string;
  title: string;
  description?: string;
  imageUrl: string;
};

interface SpeakerCardProps {
  speaker: Speaker;
  placement?: "left" | "right";
  logoColor: ColorType;
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({
  speaker,
  placement = "left",
  logoColor,
}) => {
  return (
    <div
      className={`flex w-full items-center ${
        placement === "left" ? "flex-row" : "flex-row-reverse"
      }`}
    >
      <LogoFullCircleSemicircles
        className={`w-1/3 md:w-auto ${
          logoColor === "mint"
            ? "fill-mint"
            : logoColor === "black"
            ? "fill-black"
            : "fill-orange"
        } ${placement === "left" ? "mr-4" : "ml-4 rotate-180"}`}
        width={150}
      />
      <div className="w-1/3 md:w-auto">
        <img
          className="rounded-full"
          src={speaker.imageUrl}
          alt={speaker.name}
          width={180}
        />
      </div>
      <div
        className={`flex flex-col w-1/3 md:w-full ${
          placement === "left" ? "text-left ml-4" : "text-right mr-4"
        }`}
      >
        <p className="font-sans text-lg md:text-2xl md:w-auto font-bold mb-2">{speaker.name}</p>
        <p className="font-normal text-normal md:text-xl">{speaker.title}</p>
        {/* {speaker.description && <p>{speaker.description}</p>} */}
      </div>
    </div>
  );
};

export default SpeakerCard;

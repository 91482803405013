import CONFIG from "../../config/config";
import ListElement from "../basic/ListElement";
import PrimaryButton from "../basic/PrimaryButton";
import TheHeadline from "../basic/TheHeadline";

const Agenda: React.FC = () => {
  return (
    <div className="flex flex-row">
      <div className="hidden md:block relative max-w-48 mr-10">
        <p className="top-80 font-sans text-hugeMobile md:text-huge relative -rotate-90 flex-grow-0">
          Agenda
        </p>
      </div>
      <div className="flex flex-col w-full md:max-w-80 mt-12">
        <ol>
          <ListElement>
            <TheHeadline>1. Intro</TheHeadline>
          </ListElement>
          <ListElement>9:30-10:00 Uhr: Check-in</ListElement>
          <br />
          <ListElement>
            <TheHeadline>2. Keynote Stage</TheHeadline>
          </ListElement>
          <ListElement>10:15-10:30 Uhr: Begrüßung</ListElement>
          <ListElement>10:30 Uhr: Vortrag Nr. 1</ListElement>
          <ListElement>11:15 Uhr: Vortrag Nr. 2</ListElement>
          <ListElement>
            12:00 Uhr: Eröffnung Brunch mit Sekt und Mimosas
          </ListElement>
          <ListElement>14:00 Uhr: Startschuss Learning Tracks</ListElement>
          <ListElement>16:30 Uhr: Vortrag Nr. 3</ListElement>
          <ListElement>17:30 Uhr: Closing Show</ListElement>
        </ol>
        <br />
        <PrimaryButton target="_blank" href={CONFIG.ticketStoreUrl}>
          Buy Tickets now
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Agenda;

import H2 from "../basic/H2";
import TheBodyText from "../basic/TheBodyText";
import SectionContainer from "../container/SectionContainer";
import MapImage from "../content/MapImage";

const ContactSection = () => {
  return (
    <SectionContainer
      id="section-location"
      className="flex flex-row bg-lightYellow py-7 md:py-14 justify-center"
    >
      <div className="flex flex-col md:flex-row container justify-between items-center max-w-screen-lg">
        {/* <ContactUs /> */}
        <div className="">
          <H2 className="mb-5">Die Location</H2>
          <TheBodyText className="w-full mb-5 sm:mb-0 sm:w-2/3">
            Ein echter Hotspot im Ostbahnhofsviertel - im Atelier Lihotzky
            trifft Geschichte auf Moderne. Das ehemalige Neckermann-Gebäude
            vereint den Charme der Vergangenheit mit modernem Design und bietet
            einen tollen Blick auf die Frankfurter Skyline.
          </TheBodyText>
        </div>
        <MapImage src="assets/dnz_blaue-couch_square.jpg" alt="Map" />
      </div>
    </SectionContainer>
  );
};

export default ContactSection;

import CONFIG from "../../config/config";
import TheBodyText from "../basic/TheBodyText";
import TheSubHeadline from "../basic/TheSubHeadline";
import CircleRoundedTop from "../circles/CircleRoundedTop";
import SectionContainer from "../container/SectionContainer";

const DateLocationPriceSection = () => {
  return (
    <SectionContainer
      id="section-facts"
      className="bg-lightYellow flex justify-center text-center pt-28 pb-32 overflow-hidden"
    >
      <div className="flex flex-col items-center text-center relative container max-w-screen-lg mb-4">
        <TheSubHeadline className="mb-4">
          ATELIER LIHOTZKY, Braubachstraße 18-22, 60311 Frankfurt, Deutschland
        </TheSubHeadline>
        <TheBodyText className="max-w-screen-sm mb-4">
          Ob HR-Profi, Geschäftsführer oder Employer Branding Expert – Full
          Circle bietet für jede/jeden wertvolle Inhalte. Profitiere von
          exklusiven Networking-Möglichkeiten, erfahre die neuesten Trends und
          bewährte Methoden und starte dein HR-Jahr mit einem umfassenden
          Überblick und frischen Ideen!
        </TheBodyText>
        <TheSubHeadline>
          Exklusiver Kreis von maximal 150 Teilnehmenden
          <br />
          Die vollumfassende HR-Learning Experience für nur{" "}
          <span className="whitespace-nowrap">399 €</span>
        </TheSubHeadline>
        <div className="relative">
          <CircleRoundedTop
            circleSize={400}
            text="Jetzt Ticket kaufen"
            link={CONFIG.ticketStoreUrl}
            topText={2.5}
            topPosition={3.5}
            target="_blank"
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default DateLocationPriceSection;

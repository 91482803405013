import PriceSectionLink from "../basic/PriceSectionLink";

const PriceSectionList = () => {
  return (
    <ul className="flex flex-col mb-5 list-disc pl-5">
      <li>Eintritt zum Event</li>
      <li>Teilnahme an interaktiven Learning Tracks</li>
      <li>
        Üppiger Opening Brunch bereitgestellt von der{" "}
        <PriceSectionLink
          href="https://www.frankfurterneuekueche.de/"
          target="_blank"
        >
          Frankfurter neuen Küche
        </PriceSectionLink>{" "}
        inkl. Kaffeespezialitäten und Mimosas
      </li>
      <li>
        Getränke-Flatrate (Softdrinks, Kaffee und Weinspezialitäten von{" "}
        <PriceSectionLink
          href="https://www.farbe-des-weines.de/"
          target="_blank"
        >
          Farbe des Weines
        </PriceSectionLink>
        )
      </li>
      <li>Snacks und Brainfood von ausgewählten Partnern</li>
      <li>Unvergessliche Surprise Closing Show</li>
    </ul>
  );
};

export default PriceSectionList;

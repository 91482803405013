interface H1Props {
  children?: React.ReactNode;
  className?: string;
}

const H1: React.FC<H1Props> = ({ children, className }) => {
  return (
    <h1
      className={`text-h1Mobile font-sans md:text-h1Md xl:text-h1 font-semibold text-black ${
        className || ""
      }`}
    >
      {children}
    </h1>
  );
};

export default H1;

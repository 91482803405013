import CONFIG from "../../config/config";
import ListElement from "../basic/ListElement";
import PrimaryButton from "../basic/PrimaryButton";
import TheHeadline from "../basic/TheHeadline";

const Agenda: React.FC = () => {
  return (
    <div className="flex flex-row">
      <div className="hidden md:block relative max-w-48 mr-10">
        <p className="top-[18.5rem] font-sans text-hugeMobile md:text-huge relative -rotate-90 flex-grow-0">
          Agenda
        </p>
      </div>
      <div className="flex flex-col w-full mt-12">
        <TheHeadline className="mb-1">Intro</TheHeadline>
        <ul className="mb-5 list-none">
          <ListElement time="9:30-10:00 Uhr" description="Check-in" />
        </ul>
        <TheHeadline className="mb-1">Keynote Stage</TheHeadline>
        <ul className="mb-5 list-none">
          <ListElement
            time="10:15-10:30 Uhr"
            description="Offizielle Begrüßung"
          />
          <ListElement
            time="10:30 Uhr"
            description="Julia Neuen - Wie Unternehmen mit Female Lifecycle Management & Fertility Benefits attraktiver für Frauen werden und diese besser halten können"
          />
          <ListElement
            time="11:15 Uhr"
            description="Jascha Sombrutzki - New Work, New Benefits - Was attraktive Arbeitgeber:innen 2025 ausmacht."
          />
          <ListElement
            time="12:00 Uhr"
            description="Eröffnung Brunch mit Sekt und Mimosas"
          />
          <ListElement
            time="14:00 Uhr"
            description="Startschuss Learning Tracks"
          />
          <ListElement
            time="16:30 Uhr"
            description="Malte Weiss - In 40 Tagen zum perfekten Rekrutierungsprozess"
          />
          <ListElement
            time="17:00 Uhr"
            description="Maximilian Könen - Cultural transformation after Hypergrowth"
          />
          <ListElement
            time="17:45 Uhr"
            description="Julia Kahle - Break the Tabus"
          />
          <ListElement time="18:30 Uhr" description="Surprise Closing Show" />
        </ul>
        <TheHeadline className="mb-1">Retention Track</TheHeadline>
        <ul className="mb-5 list-none">
          <ListElement
            time="14:00-14:40 Uhr"
            description="Marvin Eberhard - Ist das noch GESUND für das Unternehmen oder kann das weg?"
          />
          <ListElement
            time="14:50-15:30 Uhr"
            description="Christian Gebert - 18 Tipps zu steuerbegünstigten Gehaltsbestandteilen"
          />
          <ListElement
            time="15:40-16:20 Uhr"
            description="Nina Meyer-Blankenburg - Neue Herausforderungen im Mental Well-being am Arbeitsplatz im Zusammenhang mit New Work"
          />
        </ul>
        <TheHeadline className="mb-1">Recruiting Track</TheHeadline>
        <ul className="mb-5 list-none">
          <ListElement
            time="14:00-14:40 Uhr"
            description="Franz Wegner - TikTok Power Recruiting: 5 Ideen für mehr Bewerbungen durch 9:16"
          />
          <ListElement
            time="14:50-15:30 Uhr"
            description="Alina Langenberg - Recruiting - Wissenschaft meets Praxis"
          />
          <ListElement
            time="15:40-16:20 Uhr"
            description="Malte Weiss - KI im Rekrutierungsprozess"
          />
        </ul>
        <TheHeadline className="mb-1">Compliance Track</TheHeadline>
        <ul className="mb-5 list-none">
          <ListElement
            time="14:00-14:40 Uhr"
            description="Markus Petry – Die Lohntransparenz-Richtlinie und ihre Implikationen"
          />
          <ListElement
            time="14:50-15:30 Uhr"
            description="Dr. Timo Karsten – Arbeitsrechtliche Gestaltungsoptionen in wirtschaftlich herausfordernden Zeiten"
          />
          <ListElement
            time="15:40-16:20 Uhr"
            description="Dr. Timo Karsten – Kündigungen: Das müssen Sie arbeitsrechtlich wissen."
          />
        </ul>
        <div className="flex mt-5 w-full md:w-auto">
          <PrimaryButton
            className="w-full md:w-auto"
            target="_blank"
            href={CONFIG.ticketStoreUrl}
          >
            Ticket kaufen
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Agenda;

import "./App.css";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import AboutTheEventSection from "./components/sections/AboutTheEventSection";
import AgendaSection from "./components/sections/AgendaSection";
import ContactSection from "./components/sections/ContactSection";
import CountDownSection from "./components/sections/CountdownSection";
import DateLocationPriceSection from "./components/sections/DateLocationPriceSection";
import PriceSection from "./components/sections/PriceSection";
import SpeakersSection from "./components/sections/SpeakersSection";

function App() {
  return (
    <main>
      <Hero />
      <DateLocationPriceSection />
      <AboutTheEventSection />
      <PriceSection />
      <AgendaSection />
      <SpeakersSection />
      <CountDownSection date="2025-02-13T09:30:00" />
      <ContactSection />
      <Footer />
    </main>
  );
}

export default App;

import React from "react";
import SemiCircle from "../svg/SemiCircle";

interface CircleRoundedTopProps {
  link?: string;
  text: string;
  circleSize: number;
  topText?: number;
  topPosition?: number;
  className?: string;
  target?: string;
}

const CircleRoundedTop: React.FC<CircleRoundedTopProps> = ({
  link,
  text,
  circleSize,
  topText = 3,
  topPosition = 4,
  className = "",
  target,
}) => {
  return (
    <div
      style={{ top: `${topPosition}rem` }}
      className={`absolute -translate-x-1/2 transition-transform duration-300 text-center ${
        link ? "hover:-translate-y-1" : ""
      }`}
    >
      <SemiCircle
        href={link}
        size={circleSize}
        className="fill-orange rotate-90"
        target={target}
      />
      <span
        style={{ top: `${topText}rem` }}
        className={`absolute -translate-x-1/2 text-center font-bold text-lightYellow pointer-events-none ${className}`}
      >
        {text}
      </span>
    </div>
  );
};

export default CircleRoundedTop;

import React from "react";

export type CountDownType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const Countdown: React.FC<CountDownType> = ({
  days,
  hours,
  minutes,
  seconds,
}) => {
  const formatValue = (value: number) => {
    return value.toString().padStart(2, "0");
  };

  const renderValue = (label: string, value: number) => {
    return (
      <div className="flex flex-col items-center">
        <span className="text-countDown font-bold text-yellow">
          {formatValue(value)}
        </span>
        <span className="text-normal md:text-2xl font-light uppercase text-yellow">
          {label}
        </span>
      </div>
    );
  };

  return (
    <div
      className="flex flex-row justify-center items-center space-x-6"
      aria-live="polite"
    >
      {renderValue("Tage", days)}
      {renderValue("Stunden", hours)}
      {renderValue("Minuten", minutes)}
      {renderValue("Sekunden", seconds)}
    </div>
  );
};

export default Countdown;

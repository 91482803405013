import CONFIG from "../config/config";
import { ColorType } from "../types/ColorType";
import {
  BioType,
  ISpeaker,
  LearningsType,
  SpeakerSessionType,
} from "../types/SpeakerTypes";

export const ALINA_LANGENBERG: ISpeaker = {
  slug: "alina-langenberg",
  name: "Alina Langenberg",
  title: "Doktorandin @ WHU",
  imageUrl: CONFIG.imageUrl + "alina-langenberg.jpg",
  color: ColorType.Orange,
  details: {
    speakerSession: {
      description: ["Recruiting - Wissenschaft meets Praxis"],
      sessionType: SpeakerSessionType.Workshop,
    },
    bio: {
      title: BioType.Vita,
      description: [
        "Alina Langenberg promoviert an der WHU zum Thema Recruiting im Mittelstand und arbeitet parallel im Familienbetrieb als HRlerin.",
        "Um sich ihrem Herzensthema - der Entwicklung von Menschen und Organisationen - umfassender zu widmen, studierte sie im Master Wirtschaftspsychologie und absolvierte eine Ausbildung zum Systemischen Coach. In ihrem Alltag eint sie Erkenntnisse aus der Wissenschaft mit Erfahrungen im HR Management eines Mittelstands um Herausforderungen beider Bereiche neu zu beleuchten.",
      ],
    },
    learnings: {
      title: LearningsType.Learnings,
      description: ["In diesem Workshop lernst du:"],
      list: [
        "Welche Schritte im Recruitingprozess wichtig sind und wie diese implementiert werden können",
        "Optimierungspotenzial zu identifizieren",
        "Was die Wissenschaft in Sachen Recruiting für Learnings bietet",
      ],
    },
    contact: {
      linkedInUrl: "https://www.linkedin.com/in/alina-langenberg",
    },
  },
};

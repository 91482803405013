import BackgroundVideo from "../video/BackgroundVideo";
import HeroForeground from "./HeroForeground";

const Hero = () => {
  return (
    <section id="section-intro" className="relative w-screen h-hero font-sans z-10">
      <BackgroundVideo
        className="absolute top-0 left-0 w-full h-screen object-cover"
        video="assets/full-circle-hero.mp4"
      />
      <HeroForeground />
    </section>
  );
};

export default Hero;

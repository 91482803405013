import React from "react";

interface PrimaryButtonProps {
  children?: React.ReactNode;
  href: string;
  target?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  href,
  target,
}) => {
  return (
    <a
      href={href}
      target={target}
      className="bg-black text-xl font-bold font-sans text-lightYellow px-12 py-3 rounded-none text-center cursor-pointer"
    >
      {children}
    </a>
  );
};

export default PrimaryButton;

interface H5Props {
  children?: React.ReactNode;
  className?: string;
}

const H5: React.FC<H5Props> = ({ children, className = "" }) => {
  return <h5 className={`text-h5 font-medium ${className}`}>{children}</h5>;
};

export default H5;

import TheBodyText from "../basic/TheBodyText";
import TheHeadline from "../basic/TheHeadline";
import SectionContainer from "../container/SectionContainer";
import Agenda from "../content/Agenda";

const AgendaSection = () => {
  return (
    <SectionContainer
      id="section-agenda"
      className="relative flex flex-col bg-lightYellow py-10 items-center"
    >
      <TheHeadline className="mb-4">Das erwartet dich</TheHeadline>
      <div className="relative flex flex-col items-center container max-w-screen-lg text-center">
        <TheBodyText className="max-w-screen-md mb-4">
          Wir freuen uns, euch das Programm von Full Circle 2025 vorzustellen.
          Zwei Experten stehen bereits fest: Malte Weiss von OnApply wird über
          den Einsatz von KI im Recruiting sprechen und aufzeigen, wo diese
          Technologie schon heute sinnvoll eingesetzt werden kann. Jascha
          Sombrutzki von Benefits.me widmet sich dem Thema „Erfolgreiches
          Employer Branding“ und zeigt, wie Unternehmen sich als attraktive
          Arbeitgeber positionieren.
        </TheBodyText>
        <TheBodyText className="max-w-screen-md mb-4">
          Weitere spannende Speaker und Track-Leiter folgen in Kürze. Freut euch
          auf praxisnahe Vorträge, innovative Ansätze und interaktive Sessions,
          die euch wertvolle Impulse für euer HR-Jahr 2025 geben werden.
        </TheBodyText>
      </div>
      {/* <div className="flex flex-col md:flex-row container max-w-screen-lg justify-between"> */}
      <div className="flex flex-col md:flex-row container max-w-screen-lg justify-center mb-8">
        {/* <Sponsors sponsors={dummySponsors} /> */}
        <Agenda />
      </div>
    </SectionContainer>
  );
};

export default AgendaSection;

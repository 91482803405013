interface ListWithTitleProps {
  title: string;
  items: { text: string; href: string }[];
}

const ListWithTitle: React.FC<ListWithTitleProps> = ({ title, items }) => {
  return (
    <div className="mt-5">
      <h2 className="text-lg text-lightYellow">{title}</h2>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.href}>{item.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListWithTitle;

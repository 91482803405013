interface PriceSectionLinkProps {
  href: string;
  target: string;
  children: React.ReactNode;
}

const PriceSectionLink = ({
  href,
  target,
  children,
}: PriceSectionLinkProps) => {
  return (
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      className="font-bold relative inline-block after:content-[''] after:absolute after:bg-mint/40 after:bottom-[1px] after:left-0 after:h-[2px] after:w-full before:content-[''] before:absolute before:bg-mint before:bottom-[1px] before:left-0 before:h-[2px] before:w-full before:origin-left before:scale-x-0 before:transition-transform before:duration-300 before:ease-in-out hover:before:scale-x-100"
    >
      {children}
    </a>
  );
};

export default PriceSectionLink;

interface LogoFullCircleThreeSemicirclesProps {
  className?: string;
  width?: number;
}

const LogoFullCircleThreeSemicircles: React.FC<
  LogoFullCircleThreeSemicirclesProps
> = ({ className, width }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 150 126.9"
      width={width}
      className={className}
    >
      <path
        className="border-none"
        d="M40.3,4.4C16.7,13.6,0,36.6,0,63.5s16.7,49.8,40.3,59.1V4.4Z"
      />
      <path
        className="border-none"
        d="M40.3,4.4C16.7,13.6,0,36.6,0,63.5s16.7,49.8,40.3,59.1V4.4Z"
        transform="translate(55, 0)" // Move the second path closer to the first
      />
      <path
        className="border-none"
        d="M40.3,4.4C16.7,13.6,0,36.6,0,63.5s16.7,49.8,40.3,59.1V4.4Z"
        transform="translate(110, 0)" // Move the third path closer to the second
      />
    </svg>
  );
};

export default LogoFullCircleThreeSemicircles;

import CONFIG from "../../config/config";
import SectionContainer from "../container/SectionContainer";
import PriceBox from "../content/PriceBox";

interface PriceBoxData {
  bgColor: "orange" | "mint" | "black";
  price: number;
  headline: string;
  href?: string;
  target?: string;
}

const priceBoxes: PriceBoxData[] = [
  {
    bgColor: "orange",
    price: 399,
    headline: "Standard",
  },
  {
    bgColor: "mint",
    price: 249,
    headline: "Early Bird",
    href: CONFIG.ticketStoreUrl,
    target: "_blank",
  },
];

const PriceSection = () => {
  return (
    <SectionContainer
      id="section-prices"
      className="flex flex-col py-10 md:py-20 justify-center items-center bg-yellow"
    >
      <div className="flex flex-col sm:w-full sm:flex-row justify-around container max-w-screen-lg">
        {priceBoxes.map((box, index) => (
          <PriceBox
            key={index}
            bgColor={box.bgColor}
            price={box.price}
            headline={box.headline}
            href={box.href}
            target={box.target}
          />
        ))}
      </div>
    </SectionContainer>
  );
};

export default PriceSection;

interface TheSkyHeadlineProps {
  children?: React.ReactNode;
  className?: string;
}

const TheSkyHeadline: React.FC<TheSkyHeadlineProps> = ({
  children,
  className = "",
}) => {
  return (
    <h3
      className={`text-theSkyHeadlineMobile md:text-theSkyHeadline font-sans font-thin uppercase ${className}`}
    >
      {children}
    </h3>
  );
};

export default TheSkyHeadline;

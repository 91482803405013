interface MapImageProps {
  src: string;
  alt: string;
}

const MapImage: React.FC<MapImageProps> = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="w-full h-auto max-w-56 max-h-56 sm:max-w-full sm:max-h-full sm:w-80 sm:h-80 rounded-full contain-content"
    />
  );
};

export default MapImage;

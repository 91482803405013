import ListWithTitle from "../content/ListWithTitle";
import LogoCopyrightSocialMedia from "../content/LogoCopyrightSocialMedia";
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-row w-screen justify-center bg-black py-5 md:py-20 text-white px-5">
      <div className="flex flex-col md:flex-row container max-w-screen-lg justify-between">
        <LogoCopyrightSocialMedia className="w-1/2" />
        <ListWithTitle
          title="Sections"
          items={[
            { text: "Intro", href: "#section-intro" },
            { text: "Key Facts", href: "#section-facts" },
            { text: "Über das Event", href: "#section-event" },
            { text: "Prices", href: "#section-prices" },
            { text: "Agenda", href: "#section-agenda" },
            { text: "Speakers", href: "#section-speakers" },
            { text: "Location", href: "#section-location" },
          ]}
        />
        <ListWithTitle
          title="Information"
          items={[{ text: "Impressum", href: "https://benefits.me/imprint" }]}
        />
      </div>
    </footer>
  );
};

export default Footer;

import CONFIG from "../../config/config";
import TheBodyText from "../basic/TheBodyText";
import TheHeadline from "../basic/TheHeadline";
import TheSkyHeadline from "../basic/TheSkyHeadline";
import CircleRoundedTop from "../circles/CircleRoundedTop";
import SectionContainer from "../container/SectionContainer";

const AboutTheEventSection = () => {
  return (
    <SectionContainer
      id="section-event"
      className="bg-mint pt-6 pb-32 text-yellow flex justify-center text-center overflow-hidden"
    >
      <div className="relative flex flex-col items-center container max-w-screen-lg">
        <TheSkyHeadline>ÜBER DAS EVENT</TheSkyHeadline>
        <br />
        <TheHeadline>
          Willkommen zu Full Circle 2025 – <br />
          deiner vollumfassenden HR-Learning Experience!
        </TheHeadline>
        <br />
        <TheBodyText className="max-w-screen-sm">
          Full Circle denkt HR-Events neu und bringt eine familiäre Atmosphäre
          in die Welt des Personalwesens. Erlebe einen Tag voller Innovation,
          Inspiration und wertvollen Einblicken in die Zukunft des HR – mit
          einem besonderen Twist. Neben einem abwechslungsreichen Programm und
          praxisorientierten Learning Tracks sorgen wir für eine einzigartige
          Wohlfühlatmosphäre: Genieße Mimosas am Mittag, unlimited Snacks &
          Drinks am Tag und eine unvergessliche Closing Show am Abend.
        </TheBodyText>
        <br />
        <TheBodyText className="max-w-screen-sm">
          Dieses Event bringt führende Branchen-Köpfe in Frankfurt zusammen, um
          den gesamten HR-Prozess – von Recruiting über Retention bis hin zum
          Releasing – in einem neuen, menschlichen Licht zu beleuchten. Full
          Circle ist der ideale Ort, um dein HR-Wissen auf das nächste Level zu
          bringen und das Jahr in familiärer Atmosphäre zu starten!
        </TheBodyText>
        {/* <div className="relative">
          <CircleRoundedTop
            topText={1.5}
            circleSize={200}
            text="Mehr Lesen"
            link="/"
          />
        </div> */}
        <div className="relative">
          <CircleRoundedTop
            circleSize={500}
            text="Jetzt Early Bird Ticket kaufen"
            link={CONFIG.ticketStoreUrl}
            topText={2.5}
            topPosition={2.5}
            target="_blank"
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default AboutTheEventSection;

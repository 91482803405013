import CONFIG from "../../config/config";
import H1 from "../basic/H1";
import H2 from "../basic/H2";
import TheSubHeadline from "../basic/TheSubHeadline";
import CircleRoundedBottom from "../circles/CircleRoundedBottom";
import LogoFullCircle from "../svg/logos/LogoFullCircle";

const HeroForeground = () => {
  return (
    <div className="relative left-0 top-0 right-0 h-screen w-full flex flex-col justify-center items-center z-10 text-center">
      <div className="absolute top-0 w-full max-w-screen h-hero flex flex-col justify-center items-center">
        <div className="flex flex-col sm:flex-row items-center text-center mb-5 sm:mb-10">
          <LogoFullCircle
            className="mr-5 fill-black block md:hidden"
            width={150}
          />
          <LogoFullCircle
            className="mr-5 fill-black hidden md:block lg:hidden"
            width={200}
          />
          <LogoFullCircle
            className="mr-5 fill-black hidden lg:block xl:hidden"
            width={300}
          />
          <LogoFullCircle
            className="mr-5 fill-black hidden xl:block"
            width={400}
          />
          <H1 className="sm:text-nowrap">Full Circle 2025</H1>
        </div>
        <TheSubHeadline>
          Werde Expert*in für Recruiting, Retention & Compliance
        </TheSubHeadline>
      </div>
      <LogoFullCircle
        className="absolute top-10 left-10 fill-black"
        width={50}
      />
      <CircleRoundedBottom
        text="Ticket kaufen"
        target="_blank"
        link={CONFIG.ticketStoreUrl}
      />
      <H2 className="absolute -bottom-4">13. Februar 2025</H2>
    </div>
  );
};

export default HeroForeground;

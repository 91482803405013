import React from "react";
import LogoFullCircle from "../svg/logos/LogoFullCircle";

interface LogoCopyrightSocialMediaProps {
  className?: string;
}

const LogoCopyrightSocialMedia: React.FC<LogoCopyrightSocialMediaProps> = ({
  className,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={className}>
      <div className="flex flex-row items-center mb-4 md:mb-8 text-nowrap">
        <LogoFullCircle className="fill-white mr-4" width={80} />
        <span className="text-2xl font-sans font-bold">Full Circle</span>
      </div>
      <p>© {currentYear} Full Circle. All rights reserved.</p>
      {/* <div className="flex flex-row space-x-5">
        <a href="https://twitter.com">Twitter</a>
        <a href="https://facebook.com">Facebook</a>
        <a href="https://instagram.com">Instagram</a>
      </div> */}
    </div>
  );
};

export default LogoCopyrightSocialMedia;

interface TheBodyTextProps {
  children?: React.ReactNode;
  className?: string;
}

const TheBodyText: React.FC<TheBodyTextProps> = ({
  children,
  className = "",
}) => {
  return (
    <p
      className={`text-theBodyTextMobile md:text-theBodyText font-body leading-snug ${className}`}
    >
      {children}
    </p>
  );
};

export default TheBodyText;

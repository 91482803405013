import { ColorType } from "../types/ColorType";

interface Speaker {
  name: string;
  title: string;
  imageUrl: string;
  imageColor: ColorType;
}

const SPEAKERS: Speaker[] = [
  {
    name: "Julia Kahle",
    title: "CEO @ heynanny",
    imageUrl: "assets/julia-kahle.jpg",
    imageColor: "mint",
  },
  {
    name: "Franz Wegner",
    title: "TikTok-Papst",
    imageUrl: "assets/franz-wegner.jpeg",
    imageColor: "orange",
  },
  {
    name: "Julia Neuen",
    title: "CEO @ peaches",
    imageUrl: "assets/julia-neuen.jpeg",
    imageColor: "mint",
  },
  {
    name: "Maximilian Könen",
    title: "VP - People & Organisation @ Emma - the Sleep Company",
    imageUrl: "assets/maximilian-koenen.jpeg",
    imageColor: "mint",
  },
  {
    name: "Alina Langenberg",
    title: "Doktorandin @ WHU",
    imageUrl: "assets/alina-langenberg.jpg",
    imageColor: "orange",
  },
  {
    name: "Christian Gebert",
    title: "Geschäftsführer @ steuerberaten.de",
    imageUrl: "assets/christian-gebert.jpg",
    imageColor: "mint",
  },
  {
    name: "Nina Meyer-Blankenburg",
    title: "Team Lead Psychologists @ OpenUp",
    imageUrl: "assets/nina-meyer-blankenburg.jpg",
    imageColor: "mint",
  },
  {
    name: "Jonas Böhme",
    title:
      "Stellv. Geschäftsstellenleitung @ Bundesverband Betriebliches Gesundheitsmanagement",
    imageUrl: "assets/jonas-boehme.jpg",
    imageColor: "mint",
  },
  {
    name: "Malte Weiss",
    title: "CEO @ OnApply",
    imageUrl: "assets/malte-weiss.jpg",
    imageColor: "orange",
  },
  {
    name: "Jascha Sombrutzki",
    title: "CEO @ Benefits.me",
    imageUrl: "assets/jascha-sombrutzki.jpg",
    imageColor: "mint",
  },
];

export default SPEAKERS;

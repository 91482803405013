import { ColorType } from "../../types/ColorType";
import H5 from "../basic/H5";
import LogoFullCircleThreeSemicircles from "../svg/logos/LogoFullCircleThreeSemicircles";

interface PriceBoxProps {
  bgColor: ColorType;
  price: number;
  headline: string;
  href?: string;
  target?: string;
}

const PriceBox: React.FC<PriceBoxProps> = ({
  bgColor,
  price,
  headline,
  href,
  target,
}) => {
  return (
    <div className="flex flex-col items-center text-center mb-10 md:mb-0">
      <div className="h-10">
        <H5>{headline}</H5>
      </div>
      <br />
      <span
        className={`w-48 h-48 rounded-full text-thePrice font-bold flex justify-center items-center text-lightYellow ${
          bgColor === "mint"
            ? "bg-mint"
            : bgColor === "black"
            ? "bg-black"
            : "bg-orange"
        }`}
      >
        {price} €
      </span>
      <br />
      <a
        href={href}
        target={target}
        className={`flex items-center font-bold ${
          !href ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        Jetzt kaufen
        <LogoFullCircleThreeSemicircles className="w-6 h-6 ml-2 rotate-180" />
      </a>
    </div>
  );
};

export default PriceBox;

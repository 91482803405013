interface TheHeadlineProps {
  children?: React.ReactNode;
  className?: string;
}

const TheHeadline: React.FC<TheHeadlineProps> = ({
  children,
  className = "",
}) => {
  return (
    <h2
      className={`text-theHeadlineMobile md:text-theHeadline font-sans font-medium ${className}`}
    >
      {children}
    </h2>
  );
};

export default TheHeadline;

interface SectionContainerProps {
  children?: React.ReactNode;
  id?: string;
  className?: string;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  id,
  className,
}) => {
  return (
    <section id={id} className={`relative w-screen px-5 ${className}`}>
      {children}
    </section>
  );
};

export default SectionContainer;

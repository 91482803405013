interface LogoFullCircleProps {
  className?: string;
  width?: number;
}

const LogoFullCircle: React.FC<LogoFullCircleProps> = ({
  className,
  width,
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 247.2 126.9"
      width={width}
      className={className}
    >
      <circle
        className="border-none"
        cx="183.8"
        cy="63.5"
        r="63.5"
        transform="translate(9 148.5) rotate(-45)"
      />
      <path
        className="border-none"
        d="M113.8.1c-1.1,0-2.3-.1-3.5-.1C75.3,0,46.9,28.4,46.9,63.5s28.4,63.5,63.5,63.5,2.3,0,3.5-.1V.1h0Z"
      />
      <path
        className="border-none"
        d="M40.3,4.4C16.7,13.6,0,36.6,0,63.5s16.7,49.8,40.3,59.1V4.4Z"
      />
    </svg>
  );
};

export default LogoFullCircle;

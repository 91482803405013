import { ColorType } from "../../types/ColorType";

interface PriceCircleProps {
  bgColor: ColorType;
  price: number;
}

const PriceCircle = ({ bgColor, price }: PriceCircleProps) => {
  return (
    <div
      className={`w-72 h-72 rounded-full text-thePrice font-bold flex flex-col justify-center items-center text-lightYellow ${
        bgColor === "mint"
          ? "bg-mint"
          : bgColor === "black"
          ? "bg-black"
          : "bg-orange"
      }`}
    >
      <span className="-mb-3">{price} €</span>
      <span className="text-thePriceSubtext">netto</span>
    </div>
  );
};

export default PriceCircle;

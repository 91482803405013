// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Helvetica Neue", sans-serif;
}

.hs_email {
  position: relative;
  transform: translateX(-14%);
}

.hs_email label {
  display: none;
  position: relative;
}

.hs_email input {
  width: 12rem;
  padding: 8px;
  margin-top: 20px;
  margin-left: 0.5rem;
  transform: translateY(-30%);
}

.input {
  background-color: #fffce5;
  width: 130%;
  height: 3.5rem;
  transform: translateY(20%);
}

.hs-input {
  background-color: #fffce5;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  font-size: 1rem;
}

.hs-button {
  position: absolute;
  top: 0px;
  left: 92%;
  width: 40px;
  height: 40px;
  background-color: #fffce5;
  color: black;
  border: 1px solid black;
  padding: 6px 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  font-family: Degular, sans-serif;
}

.submitted-message {
  width: 14rem;
  color: #fffce5;
  margin-top: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["body {\n  font-family: \"Helvetica Neue\", sans-serif;\n}\n\n.hs_email {\n  position: relative;\n  transform: translateX(-14%);\n}\n\n.hs_email label {\n  display: none;\n  position: relative;\n}\n\n.hs_email input {\n  width: 12rem;\n  padding: 8px;\n  margin-top: 20px;\n  margin-left: 0.5rem;\n  transform: translateY(-30%);\n}\n\n.input {\n  background-color: #fffce5;\n  width: 130%;\n  height: 3.5rem;\n  transform: translateY(20%);\n}\n\n.hs-input {\n  background-color: #fffce5;\n  width: 100%;\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 10px;\n  font-size: 1rem;\n}\n\n.hs-button {\n  position: absolute;\n  top: 0px;\n  left: 92%;\n  width: 40px;\n  height: 40px;\n  background-color: #fffce5;\n  color: black;\n  border: 1px solid black;\n  padding: 6px 10px;\n  font-size: 1rem;\n  cursor: pointer;\n  margin-top: 20px;\n  font-family: Degular, sans-serif;\n}\n\n.submitted-message {\n  width: 14rem;\n  color: #fffce5;\n  margin-top: 0.5rem;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

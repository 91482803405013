interface TheSubHeadlineProps {
  children?: React.ReactNode;
  className?: string;
}

const TheSubHeadline: React.FC<TheSubHeadlineProps> = ({
  children,
  className = "",
}) => {
  return (
    <h4
      className={`text-theSubHeadlineMobile md:text-theSubHeadline font-sans font-light ${className}`}
    >
      {children}
    </h4>
  );
};

export default TheSubHeadline;

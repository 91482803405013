interface H2Props {
  children?: React.ReactNode;
  className?: string;
}

const H2: React.FC<H2Props> = ({ children, className }) => {
  return (
    <h2 className={`text-h2Mobile md:text-h2 font-sans font-semibold text-black ${className}`}>
      {children}
    </h2>
  );
};

export default H2;

import SPEAKERS from "../../data/speakers";
import H2 from "../basic/H2";
import CircleRoundedTop from "../circles/CircleRoundedTop";
import SectionContainer from "../container/SectionContainer";
import NewsletterRegistration from "../content/NewsletterRegistration";
import SpeakerCard from "../content/SpeakerCard";

const SpeakersSection = () => {
  return (
    <SectionContainer
      id="section-speakers"
      className="flex justify-center bg-yellow pt-5 md:pt-20 pb-52 overflow-hidden"
    >
      <div className="flex flex-col items-center container max-w-screen-lg">
        <div className="mb-10 w-full text-right">
          <H2 className="mb-3">Meet our speakers</H2>
        </div>
        <div className="flex flex-col w-full space-y-10">
          {SPEAKERS.map((speaker, index) => (
            <SpeakerCard
              key={speaker.name + index}
              speaker={speaker}
              logoColor={speaker.imageColor}
              placement={index % 2 === 0 ? "left" : "right"}
            />
          ))}
        </div>
        <div className="relative">
          <CircleRoundedTop
            topText={6.25}
            topPosition={2.5}
            circleSize={800}
            text="Lass uns deine E-Mail-Adresse da, um über Neuigkeiten informiert zu werden"
            className="text-lg px-5 md:px-0"
          />
          <div className="absolute -translate-x-1/2 top-16">
            <NewsletterRegistration />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default SpeakersSection;

import React from "react";

interface ListElementProps {
  children?: React.ReactNode;
}

const ListElement: React.FC<ListElementProps> = ({ children }) => {
  return <li className="text-theBodyText">{children}</li>;
};

export default ListElement;

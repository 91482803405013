interface BackgroundVideoProps {
  video: string;
  className?: string;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({
  video,
  className,
}) => {
  return (
    <video className={className} autoPlay muted loop playsInline>
      <source src={video} type="video/mp4" />
    </video>
  );
};

export default BackgroundVideo;

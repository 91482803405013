import React from "react";

interface ListElementProps {
  time: string;
  description: string;
  className?: string;
}

const ListElement: React.FC<ListElementProps> = ({
  time,
  description,
  className,
}) => {
  return (
    <li className={`list-item text-theBodyText mb-1.5 ${className}`}>
      <div className="grid grid-cols-[8.5rem_1fr]">
        <span className="font-bold">{time}:</span>
        <span>{description}</span>
      </div>
    </li>
  );
};

export default ListElement;

import CONFIG from "../../config/config";
import { ColorType } from "../../types/ColorType";
import H5 from "../basic/H5";
import TheHeadline from "../basic/TheHeadline";
import SectionContainer from "../container/SectionContainer";
import PriceCircle from "../content/PriceCircle";
import PriceSectionList from "../content/PriceSectionList";
import LogoFullCircleThreeSemicircles from "../svg/logos/LogoFullCircleThreeSemicircles";

interface PriceCircleData {
  bgColor: ColorType;
  price: number;
  headline?: string;
  headlineAdditional?: string;
  href?: string;
  target?: string;
}

const priceCircles: PriceCircleData = {
  bgColor: "orange",
  price: 399,
  href: CONFIG.ticketStoreUrl,
  target: "_blank",
};

const PriceSection = () => {
  return (
    <SectionContainer
      id="section-prices"
      className="flex flex-col py-10 justify-center items-center bg-yellow"
    >
      <TheHeadline className="text-center mb-10">
        Sichere dir jetzt dein Ticket
      </TheHeadline>
      <div className="flex flex-col md:flex-row justify-evenly mb-5">
        <div className="flex justify-center items-center flex-1 mb-10 md:mb-0">
          <PriceCircle
            bgColor={priceCircles.bgColor}
            price={priceCircles.price}
          />
        </div>
        <div className="flex justify-center items-center flex-1">
          <div className="flex flex-col">
            <H5 className="mb-2">Im Ticketpreis enthalten:</H5>
            <PriceSectionList />
            <div className="flex justify-center md:justify-start w-full">
              <a
                href={CONFIG.ticketStoreUrl}
                target="_blank"
                className={`flex items-center font-bold text-2xl ${
                  !CONFIG.ticketStoreUrl ? "opacity-50 cursor-not-allowed" : ""
                }`}
                rel="noreferrer"
              >
                Jetzt kaufen
                <LogoFullCircleThreeSemicircles className="w-8 h-8 ml-2 rotate-180" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default PriceSection;

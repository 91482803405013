interface SemiCircleProps {
  className?: string;
  size: number;
  href?: string;
  target?: string;
}

const SemiCircle: React.FC<SemiCircleProps> = ({
  className,
  size,
  href,
  target,
}) => {
  const svgElement = (
    <svg
      width={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M 50,0 A 50,50 0 1,0 50,100 L 50,50 Z" />
    </svg>
  );

  return href ? (
    <a target={target} href={href}>
      {svgElement}
    </a>
  ) : (
    svgElement
  );
};

export default SemiCircle;
